import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import '../assets/css/modal.css';
const BASE_URL = process.env.REACT_APP_BASE_URL;

class SubTopicLLMModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // generated summary
      conversations: props.conversations,
      inputContent: props.inputContent,
      answerGenerating: false,
      chatMessages : props.chatMessages,
      chatInput: '',
    };
    this.handleChatInputChange = this.handleChatInputChange.bind(this);
    this.handleChatSubmit = this.handleChatSubmit.bind(this);
    this.handleLLMCall = this.handleLLMCall.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.conversations !== this.props.conversations) {
      this.setState({ conversations: nextProps.conversations});
    }
    if (nextProps.inputContent !== this.props.inputContent) {
      this.setState({ inputContent: nextProps.inputContent});
    }
    if (nextProps.chatMessages !== this.props.chatMessages) {
      this.setState({ chatMessages: nextProps.chatMessages});
    }
  }

  handleLLMCall(chatInput) {
    this.setState({ answerGenerating: true });
    const url = `${BASE_URL}/api/nlp_explorer/nlp_chat`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: chatInput, context: this.state.inputContent }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        const newMessage = { user: 'ai', text: data.answer };
        this.setState(prevState => ({
          chatMessages: [...prevState.chatMessages, newMessage],
        }));
        this.setState({ answerGenerating: false });
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  handleChatInputChange(event) {
    this.setState({ chatInput: event.target.value });
  }

  handleChatSubmit() {
    const newMessage = { user: 'user', text: this.state.chatInput };
    this.setState(prevState => ({
      chatMessages: [...prevState.chatMessages, newMessage],
      chatInput: ''
    }));
    this.handleLLMCall(this.state.chatInput);
  }

  render() {
    const modalStyle = {
        margin: '10 auto',
        transition: 'all .8s',
    };

    const headerStyle = {
        background: 'lightSteelBlue',
    };

    const bodyStyle = {
        background: '#F6F6F6',
    };
    return (
      <Modal 
        id='SubtopicLLMModal'
        centered
        style={modalStyle}
        // className="modal-style"
        animation={false}
        show={this.props.show} 
        onHide={this.props.handleClose}>
        <Modal.Header style={headerStyle} closeButton>
          <Modal.Title>LLM Recommendations</Modal.Title>
        </Modal.Header>
        <Modal.Body 
            style={bodyStyle}
            // className="modal-content show-grid"
            >
          {/* <Form>
            <Form.Group controlId="formConversations">
              <Form.Label>Conversations</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={this.state.conversations}
                onChange={this.handleChange}
                placeholder="Enter conversations here"
              />
            </Form.Group>
          </Form> */}
            <div className="modal-content-container">
            <div className="left-column">
            {this.props.conversations?
              <div dangerouslySetInnerHTML={{ __html: this.state.conversations }} />:
              <div>
                <div
                  className="info-preloader-handler"
                  style={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height: 'auto',
                    zIndex: 15,
                    background: 'transparent',
                    borderRadius: '5px',
                    padding: '10px',
                  }}
                >
                  <div className="preloader-text">
                    <span>Loading...</span>
                    <br />
                    <i className="fa fa-spinner fa-spin"></i>
                  </div>
                </div>
              </div>
            }
            {this.props.svgContent? <div ref={node => node && node.appendChild(this.props.svgContent)} /> : null}
            </div>
            {!this.state.answerGenerating?
            <div className="right-column">
              <div className="chat-display-area">
                {this.state.chatMessages.map((message, index) => (
                  <div key={index} className={`chat-message ${message.user}`}>
                    <div dangerouslySetInnerHTML={{ __html: message.text}} />
                  </div>
                ))}
              </div>
              <Form className="chat-input-form">
                <Form.Group controlId="formChatInput" className="chat-input-group">
                  <Form.Control
                    type="text"
                    value={this.state.chatInput}
                    onChange={this.handleChatInputChange}
                    placeholder="Enter your message here"
                  />
                </Form.Group>
                <Button variant="primary" onClick={this.handleChatSubmit} className="chat-submit-button">
                  Send
                </Button>
              </Form>
            </div>
            : 
            <div>
              <div className="right-column">
                <div className="chat-display-area" style={{height: '75vh',textAlign: 'center',alignContent: 'center', minWidth: '35vw'}}>
                  <h4>Loading...</h4>
                </div>
              <Form className="chat-input-form">
                <Form.Group controlId="formChatInput" className="chat-input-group">
                  <Form.Control
                    type="text"
                    value={this.state.chatInput}
                    onChange={this.handleChatInputChange}
                    placeholder="Enter your message here"
                  />
                </Form.Group>
                <Button variant="primary" onClick={this.handleChatSubmit} className="chat-submit-button" disabled>
                  Send
                </Button>
              </Form>
            </div>
            </div>
            }
          </div>
        </Modal.Body >
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SubTopicLLMModal;